import React, { CSSProperties } from "react";
import {
  cartoon_guy_handshake,
  cartoon_guy_road_thumbs_up,
  cartoon_guy_with_question_marks_png,
  twinOakSquirrelConversionImage,
} from "../images";
import { Heading2 } from "./Heading2";
import { Heading3 } from "./Heading3";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";

const leftImageStyle: CSSProperties = {
  width: "220px",
  float: "left",
  padding: "8px 40px 0 0",
};
const rightImageStyle: CSSProperties = {
  width: "320px",
  float: "right",
  padding: "8px 0 0 20px",
};

/**
 * We need this style to play nice with the floating images. Without this, the bullets push out to the left for some
 * reason.
 */
const listStyle: CSSProperties = { display: "inline-block" };

export const WhoWeAre: React.FC = () => {
  return (
    <>
      <PageHeader
        primary="Who We Are"
        secondary="We specialize in bringing your AS/400 RPG assets to the cloud."
        visible={false}
      />
      <Section>
        <div className="content_set">
          <img
            src={cartoon_guy_with_question_marks_png}
            style={leftImageStyle}
          ></img>
          {/* <img src={cartoon_guy_handshake_png} style={{ float: "left" }}></img> */}
          <Heading2>Do you need to move your legacy RPG system to the web?</Heading2>
          <Paragraph>
          Our first generation of products are designed to move your legacy RPG, CL, 
          and data from the AS/400 to Microsoft-based technologies in the cloud or an on-premises server.
          <br/><br/>
           Our solution helps you solve the common problems faced by legacy RPG systems, including:<br />
          
          <ul style={{ display: "inline-block" }}>
            <li>Hardware and Software Support Costs</li>
            <li>Compatibility with new systems </li>
            <li>Dated look-and-feel</li>
            <li>Limiting hosting options to include the cloud</li>
            <li>Retiring staff is hard to replace</li>
            <li>Shrinking pool of legacy skills</li>
          </ul>
        </Paragraph>
        </div>
        
        <div className="content_set">
          <Heading2>We Modernize Your Legacy System Using Automated Translation</Heading2>
          <Paragraph>
            Our translation software reads your RPG programs and procedures and rewrites them in C#.  
            We provide an environment for running the translated software on a web server and is accessed with a web browser.
            We also convert your data for the new hosting environment or a database server.
            Your system transforms into something completely modern.
            <br />
            <br />
            Our approach has several advantages over other traditional software conversion methods.  These advantages include:
          </Paragraph>
          <img src={cartoon_guy_handshake} style={rightImageStyle} />
          <div className="pc_compare">
            <ul>
              <li>Runs in a web browser, making it simple to access and familiar to your employees.</li>
              <li>Preserves the business value captured in your existing code</li>
              <li>
                All of your assets - menus, data, forms, code, and procs - are
                converted rapidly and simultaneously without the typical
                long-running development project
              </li>
              <li>Going forward you can use the latest tools and technologies</li>
              <li>
                Unlike some other conversion solutions you own{" "}
                <em>all of the translated software</em>
              </li>
              <li>
                You can continue to evolve the translated code base or write
                completely new modern code, or do both and mix them in the
                existing application
              </li>
              <li>Menus and screens stay the same, keeping it familiar to your employees.</li>
              <li>The overall structure of your code remains intact, keeping it familiar to your developers.</li>
              <li>
                Your existing programmers can learn new technology from familiar,
                translated code
              </li>
              <li>
                New developers can learn your existing business from the modernized
                code
              </li>
            </ul>
          </div>
        </div>
        
        
      </Section>
    </>
  );
};
