import React from "react";
import { PageLayout } from "../components/PageLayout";
import { WhoWeAre } from "../components/WhoWeAre";
import { homePageHeroImage } from "../images";

const HomePage: React.FC = () => {
  return (
    <PageLayout heroBackgroundImage={`url(${homePageHeroImage})`}
    heroOverlayLeadElement="RPG System Modernization"
    heroOverlaySecondaryElement="Move your legacy assets to the web.">
      <WhoWeAre />
    </PageLayout>
  );
};

export default HomePage;
